@use "sass:math";

@use "../themes/themes";
@use "../utils/mixins";
@use "../utils/variables";
@use "../utils/animations";

:root {
    //--bs-gutter-x: 1.5rem;
    //--bs-gutter-y: 1.5rem;
}

html {
    width: 100%;
    min-height: 100%;
    font-size: 17px;
    font-family: "Open Sans", sans-serif;

    @include mixins.breakpoint(themes.$xs) {
        font-size: 16px;
    }
}

html,
body {
    overscroll-behavior-x: none;
}

.bg-inherit {
    background: inherit !important;
}

.mobile {
    font-size: 1.1rem;
}

.fas,
.far {
    &[data-text] {
        position: relative;

        &:after {
            content: attr(data-text);
            position: absolute;
            top: 0;
            left: 0;
            font-size: 0.6rem;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: flex-end;
            font-family: "Open Sans", sans-serif;
            padding-bottom: 0.6rem;
            display: flex;
        }
    }
}

body {
    width: 100%;
    min-height: 100vh;
    pointer-events: all !important;

    //klasa dodawana przy scrollu na modalu i body do wylaczania dotyku na poszczegolnych elementach
    &.no-events {
        .fetura-slider,
        .fetura-button {
            pointer-events: none !important;
            cursor: all-scroll;
        }
    }

    &.disable-overscroll {
        overscroll-behavior-y: contain;
    }

    * {
        &:focus {
            outline: 1;
        }
    }

    &.rfid-app {
        background: #f6f6f6;
    }

    &.helper-closed {
        .fetura-button-group {
            &.fixed {
                right: 3.5rem !important;
            }
        }
    }

    &.helper-opened {
        .fetura-button-group {
            &.fixed {
                right: 45rem !important;
            }
        }
    }
}

.mw-0 {
    min-width: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    display: flex;
    min-width: 0;
    @include mixins.noBreakWord();
}

.no-pointer-events {
    pointer-events: none !important;
}

#root {
    position: relative;
    min-height: 100vh;

    @include themes.themify(themes.$themes) {
        color: themes.textContrast(themes.themed("background300"));
        background: themes.themed("background300");
    }
}

.container-fluid {
    min-height: 100vh;
}

.App {
    @include themes.themify(themes.$themes) {
        min-height: 100vh;
    }

    &.blur {
        filter: blur(1rem);
    }
}

ul:not(.helper-tutorial) {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
}

ul {
    &.helper-tutorial {
        > li {
            > span {
                font-weight: 500;

                &.error {
                    @include themes.themify(themes.$themes) {
                        color: themes.themed("error500");
                    }
                }

                &.info {
                    @include themes.themify(themes.$themes) {
                        color: themes.themed("info500");
                    }
                }

                &.warning {
                    @include themes.themify(themes.$themes) {
                        color: themes.themed("warning500");
                    }
                }

                &.success {
                    @include themes.themify(themes.$themes) {
                        color: themes.themed("success500");
                    }
                }
            }
        }
    }
}

.content {
    margin-right: -0.75rem;
    margin-left: -0.75rem;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    transition: margin-left 200ms ease-in-out;

    > div {
        &:not(.farm-expiration-status):not(.view-container-modal):not(
                .cookie-acceptance
            ):not(.gateway-no-connection-banner) {
            min-height: 100vh;
        }
    }

    .gateway-no-connection-banner {
        height: 2rem;
        width: 100%;

        + div {
            min-height: calc(100vh - 2rem) !important;
        }
    }

    .farm-expiration-status {
        + div:not(.gateway-no-connection-banner) {
            min-height: calc(100vh - 2rem) !important;

            header {
                &.mobile {
                    padding-left: 0 !important;
                    padding-right: 0 !important;

                    h2 {
                        padding-left: 4.5rem;
                    }
                }
            }
        }

        &.expanded {
            + div {
                min-height: calc(100vh - 4rem);
            }
        }
    }

    .gateway-no-connection-banner + .farm-expiration-status + div,
    .farm-expiration-status + .gateway-no-connection-banner + div {
        min-height: calc(100vh - 4rem) !important;
    }

    > * {
        width: 100%;
    }
}

a,
a:not([href]) {
    $density: 50;
    text-decoration: none !important;

    @include themes.themify(themes.$themes) {
        $bgColor: themes.themed("background" + $density);
        color: themes.textContrast($bgColor);

        &:hover {
            color: themes.textContrast($bgColor);
        }
    }
}

h1,
h2,
h3,
h4,
h5 {
    margin-bottom: 0;
    line-height: 1.5;
}

.pointer {
    cursor: pointer;
}

.no-content {
    content: "";
}

@keyframes highlight {
    0% {
        background: #ffff99;
    }

    100% {
        background: none;
    }
}

.highlight-animation {
    animation: highlight 4s;
}

.hide-on-small-device {
    &:not(.xs, .sm, .md, .lg, .xl) {
        @include mixins.hideOnSmallDevices();
    }

    &.xs {
        @include mixins.hideOnSmallDevices(themes.$xs);
    }

    &.sm {
        @include mixins.hideOnSmallDevices(themes.$sm);
    }

    &.md {
        @include mixins.hideOnSmallDevices(themes.$md);
    }

    &.lg {
        @include mixins.hideOnSmallDevices(themes.$lg);
    }

    &.xl {
        @include mixins.hideOnSmallDevices(themes.$xl);
    }
}

.show-on-small-device {
    display: none;

    &:not(.xs, .sm, .md, .lg, .xl) {
        @include mixins.showOnSmallDevice();
    }

    &.xs {
        @include mixins.showOnSmallDevice(themes.$xs);
    }

    &.sm {
        @include mixins.showOnSmallDevice(themes.$sm);
    }

    &.md {
        @include mixins.showOnSmallDevice(themes.$md);
    }

    &.lg {
        @include mixins.showOnSmallDevice(themes.$lg);
    }

    &.xl {
        @include mixins.showOnSmallDevice(themes.$xl);
    }
}

.no-break-word {
    @include mixins.noBreakWord();

    &.left {
        @include mixins.noBreakWord(left);
    }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.empty-list {
    text-align: center;
    padding: themes.$paddingBigHorizontal;
}

.align-right {
    text-align: right;
}

.fieldset {
    &.info {
        @include themes.themify(themes.$themes) {
            border: 1px solid themes.themed("info500");
        }
    }

    &.warning {
        @include themes.themify(themes.$themes) {
            border: 1px solid themes.themed("warning500");
        }
    }

    &.error {
        @include themes.themify(themes.$themes) {
            border: 1px solid themes.themed("error500");
        }
    }

    &.success {
        @include themes.themify(themes.$themes) {
            border: 1px solid themes.themed("success500");
        }
    }

    &.selected {
        @include themes.themify(themes.$themes) {
            color: themes.textContrast(themes.themed("success500"));
            border: 1px solid themes.textContrast(themes.themed("success500"));
        }
    }

    > .fetura-input:last-child {
        margin-bottom: 0;
    }
}

hr {
    position: relative;

    @include themes.themify(themes.$themes) {
        border-top-color: themes.themed("secondary500");
    }

    &:before,
    &:after {
        content: "";
        position: absolute;
        width: 5%;
        height: 1px;
        top: -1px;
    }

    &:before {
        left: 0;

        @include themes.themify(themes.$themes) {
            $bgColor: themes.themed("background50");
            background: linear-gradient(
                90deg,
                $bgColor 20%,
                themes.themed("secondary500")
            );
        }
    }

    &:after {
        right: 0;

        @include themes.themify(themes.$themes) {
            $bgColor: themes.themed("background50");
            background: linear-gradient(
                -90deg,
                $bgColor 20%,
                themes.themed("secondary500")
            );
        }
    }
}

i {
    @include themes.themify(themes.$themes) {
        &.success {
            color: themes.themed("primary500");
        }

        &.error {
            color: themes.themed("error500");
        }

        &.warning {
            color: themes.themed("warning500");
        }

        &.info {
            color: themes.themed("info500");
        }

        &.disabled {
            color: themes.themed("secondary100");
        }
    }
}

.switch-wrapper {
    position: relative;
}

.switch-wrapper > div {
    position: absolute;
}

.theme-dark {
    background: black;
    opacity: 0.9;

    .img-filter {
        opacity: 0.7;
        filter: grayscale(0.5);
    }
}

.img-512px {
    max-width: 512px !important;
}

.img-256px {
    max-width: 256px !important;
}

//.theme-contrast {
//  color: #33cc33 !important;
//  background-color: black !important;
//
//  * {
//    color: #33cc33 !important;
//    background-color: black !important;
//  }
//}

label {
    //@include mixins.noBreakWord();
    width: 100%;
    //font-size: 1rem;
    margin-bottom: 0;
    font-weight: 400;

    sup {
        @include themes.themify(themes.$themes) {
            color: themes.themed("error500");
        }
    }

    .fetura-button {
        margin-bottom: 0 !important;
    }
}

.dl,
ol {
    margin-bottom: 0;
}

ul {
    margin-bottom: 0.5rem;
}

.placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15rem;
    background: black;
    color: white;
    font-size: 4rem;
    margin-bottom: 1rem;
}

.draw-long-shadow {
    box-shadow:
        0px 8px 10px -5px rgba(0, 0, 0, 0.2),
        0px 16px 24px 2px rgba(0, 0, 0, 0.14),
        0px 6px 1.5rem 5px rgba(0, 0, 0, 0.12) !important;
}

.overflow-visible {
    overflow: visible !important;
}

.overflow-unset {
    overflow: unset !important;
}

.rtl {
    direction: rtl;
}

.opacity-100 {
    opacity: 1 !important;
}

.opacity-75 {
    opacity: 0.75 !important;
}

.opacity-50 {
    opacity: 0.5 !important;
}

.opacity-25 {
    opacity: 0.25 !important;
}

.opacity-0 {
    opacity: 0 !important;
}

.shadow-big {
    @include mixins.shadow(big);
}

a[disabled] {
    pointer-events: none;
}

.pointer-events-none {
    pointer-events: none;
}

.notice-me {
    //position: relative;
    &:after {
        content: " ";
        position: absolute;
        opacity: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        @include themes.themify(themes.$themes) {
            background: #{themes.themed("info500")}0F;
            border: 5px solid themes.themed("info500");
        }
    }
}

@keyframes threeDotsAnimation {
    0% {
        content: ".";
    }

    50% {
        content: "..";
    }

    100% {
        content: "...";
    }
}

.loading-animation::after {
    content: ".";
    position: absolute;
    animation: threeDotsAnimation 1000ms linear infinite;
}

.fas {
    &.fas-as-svg-wrapper {
        > i {
            overflow: hidden;
        }

        svg {
            fill: currentColor;
            width: 1em;
            height: 1em;
            overflow: hidden;
            transform: scale(1.7);
        }
    }
}

.max-height-2rem {
    max-height: 2rem;
}

.mh-5rem {
    min-height: 5rem;
}

.mh-10rem {
    min-height: 10rem;
}

.mh-15rem {
    min-height: 15rem;
}

.mh-20rem {
    min-height: 20rem;
}

.mh-25rem {
    min-height: 25rem;
}

.h-10rem {
    height: 10rem;
}

.h-20rem {
    height: 20rem;
}

.h-25rem {
    height: 25rem;
}

.mw-5rem {
    min-width: 5rem;
}

.h-0 {
    height: 0;
}

.color-success,
.color-primary {
    @include themes.themify(themes.$themes) {
        color: themes.themed("primary500");
    }
}

.color-secondary {
    @include themes.themify(themes.$themes) {
        color: themes.themed("secondary500");
    }
}

.color-info {
    @include themes.themify(themes.$themes) {
        color: themes.themed("info500");
    }
}

.color-error {
    @include themes.themify(themes.$themes) {
        color: themes.themed("error500");
    }
}

.color-warning {
    @include themes.themify(themes.$themes) {
        color: themes.themed("warning500");
    }
}

.color-yellow {
    @include themes.themify(themes.$themes) {
        color: themes.themed("yellow500") !important;
    }
}

.color-orange {
    @include themes.themify(themes.$themes) {
        color: themes.themed("orange500") !important;
    }
}

.color-green {
    @include themes.themify(themes.$themes) {
        color: themes.themed("green500") !important;
    }
}

.color-blue {
    @include themes.themify(themes.$themes) {
        color: themes.themed("blue500") !important;
    }
}

.background-success {
    @include themes.themify(themes.$themes) {
        background: themes.themed("success500");
        color: themes.textContrast(themes.themed("success500"));
    }
}

.background-primary {
    @include themes.themify(themes.$themes) {
        background: themes.themed("primary500");
        color: themes.textContrast(themes.themed("primary500"));
    }
}

.background-secondary {
    @include themes.themify(themes.$themes) {
        background: themes.themed("secondary500");
        color: themes.textContrast(themes.themed("secondary500"));
    }
}

.background-info {
    @include themes.themify(themes.$themes) {
        background: themes.themed("info500");
        color: themes.textContrast(themes.themed("info500"));
    }
}

.background-error {
    @include themes.themify(themes.$themes) {
        background: themes.themed("error500");
        color: themes.textContrast(themes.themed("error500"));
    }
}

.background-warning {
    @include themes.themify(themes.$themes) {
        background: themes.themed("warning500");
        color: themes.textContrast(themes.themed("warning500"));
    }
}

.background-disabled {
    @include themes.themify(themes.$themes) {
        background: themes.themed("disabled500");
        color: themes.textContrast(themes.themed("disabled500"));
    }
}

.animate-transform-x {
    transition: all 200ms themes.$easeStandard !important;
}

.background-inherit {
    background: inherit;
}

.sticky-item {
    position: sticky;
    background: inherit;
    top: 0;
    z-index: 2;

    &-md {
        position: sticky;
        background: inherit;
        top: 0;
        z-index: 1;
        height: fit-content;

        @include mixins.breakpointWithHelper(themes.$sm) {
            position: inherit;
        }
    }

    &-lg {
        position: sticky;
        background: inherit;
        top: 0;
        z-index: 1;
        height: fit-content;

        @include mixins.breakpointWithHelper(themes.$md) {
            position: inherit;
        }
    }

    &-xl {
        position: sticky;
        background: inherit;
        top: 0;
        z-index: 1;
        height: fit-content;

        @include mixins.breakpointWithHelper(themes.$lg) {
            position: inherit;
        }
    }
}

.pt-1rem {
    padding-top: 1rem;
}

.lock-scroll {
    overflow: hidden !important;
}

.helper {
    &.lock-scroll-3 {
        .helper-sidebar {
            &:not(.mobile-screen) {
                right: 1.25rem !important;
            }
        }
    }
}

@for $i from 1 through 10 {
    .lock-scroll-#{$i} {
        overflow: hidden !important;
    }
}

.fixed-left {
    left: 0 !important;
}

.fixed-right {
    right: 0 !important;
}

.w-inherit {
    width: inherit !important;
}

@mixin makeLightBg($color) {
    .background-#{$color}-light {
        @include themes.themify(themes.$themes) {
            background: themes.themed(#{$color}+ "100");
            color: themes.themed(#{$color}+ "900");
        }
    }
}

@include makeLightBg("info");
@include makeLightBg("success");
@include makeLightBg("error");
@include makeLightBg("warning");

.background-success-dark {
    @include themes.themify(themes.$themes) {
        background: themes.themed("success700");
        color: themes.textContrast(themes.themed("success700"));
    }
}

.background-primary-dark {
    @include themes.themify(themes.$themes) {
        background: themes.themed("primary700");
        color: themes.textContrast(themes.themed("primary700"));
    }
}

.background-secondary-dark {
    @include themes.themify(themes.$themes) {
        background: themes.themed("secondary700");
        color: themes.textContrast(themes.themed("secondary700"));
    }
}

.background-info-dark {
    @include themes.themify(themes.$themes) {
        background: themes.themed("info700");
        color: themes.textContrast(themes.themed("info700"));
    }
}

.background-error-dark {
    @include themes.themify(themes.$themes) {
        background: themes.themed("error700");
        color: themes.textContrast(themes.themed("error700"));
    }
}

.background-warning-dark {
    @include themes.themify(themes.$themes) {
        background: themes.themed("warning700");
        color: themes.textContrast(themes.themed("warning700"));
    }
}

.background-disabled-dark {
    @include themes.themify(themes.$themes) {
        background: themes.themed("disabled700");
        color: themes.textContrast(themes.themed("disabled700"));
    }
}

.background-success-dark,
.background-primary-dark {
    @include themes.themify(themes.$themes) {
        background: themes.themed("primary700");
        color: themes.textContrast(themes.themed("primary700"));
    }
}

.background-secondary-dark {
    @include themes.themify(themes.$themes) {
        background: themes.themed("secondary700");
        color: themes.textContrast(themes.themed("secondary700"));
    }
}

.background-info-dark {
    @include themes.themify(themes.$themes) {
        background: themes.themed("info700");
        color: themes.textContrast(themes.themed("info700"));
    }
}

.background-error-dark {
    @include themes.themify(themes.$themes) {
        background: themes.themed("error700");
        color: themes.textContrast(themes.themed("error700"));
    }
}

.background-warning-dark {
    @include themes.themify(themes.$themes) {
        background: themes.themed("warning700");
        color: themes.textContrast(themes.themed("warning700"));
    }
}

.background-disabled-dark {
    @include themes.themify(themes.$themes) {
        background: themes.themed("disabled700");
        color: themes.textContrast(themes.themed("disabled700"));
    }
}

.overflow-sm-visible {
    @media (min-width: 576px) {
        overflow: visible !important;
    }
}

code {
    @include themes.themify(themes.$themes) {
        display: block;
        color: themes.themed("background700");
        background: themes.themed("background300");
        padding: 0.2rem;
        border-radius: 0.2rem;
    }
}

.border-right {
    @include themes.themify(themes.$themes) {
        border-right: 1px solid themes.themed("background500") !important;
    }
}

.border-left {
    @include themes.themify(themes.$themes) {
        border-left: 1px solid themes.themed("background500") !important;
    }
}

.opacity-hover-25 {
    &:hover {
        opacity: 0.25 !important;
    }
}

.opacity-hover-50 {
    &:hover {
        opacity: 0.5 !important;
    }
}

.opacity-hover-75 {
    &:hover {
        opacity: 0.75 !important;
    }
}

.opacity-hover-100 {
    &:hover {
        opacity: 1 !important;
    }
}

.scale-hover-element {
    transition: transform 200ms ease-in-out;

    &:hover {
        transform: scale(1.07);
    }
}

.border-font-color {
    @include themes.themify(themes.$themes) {
        border: 1px solid themes.themed("fontColor") !important;
    }
}

@keyframes jiggleIcon {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-4px);
    }

    60% {
        transform: translateY(-2px);
    }
}

section {
    @include themes.themify(themes.$themes) {
        color: themes.textContrast(themes.themed("background50"));
        background: #{themes.themed("background50")}C0;
        border-radius: 4px;
    }
}

// storybook
.sb-show-main {
    padding: 0 !important;

    &:not(.sb-main-fullscreen) {
        #root {
            padding: 1rem;
        }
    }
}

body {
    &.helper-opened {
        //$drawer-breakpoints: ("xl": $xl, "lg": $lg, "md": $md, "sm": $sm, "xs": 0);
        $drawer-breakpoints: (
            "xs": 0,
            "sm": themes.$sm,
            "md": themes.$md,
            "lg": themes.$lg,
            "xl": themes.$xl,
        );

        @each $name, $size in $drawer-breakpoints {
            @for $i from 1 through 12 {
                @media (min-width: #{$size+themes.$rightHelperDrawerWidth}) {
                    @if $name == "xs" {
                        .col-#{$i} {
                            flex: 0 0 percentage(math.div($i, 12));
                            max-width: percentage(math.div($i, 12));
                        }
                    } @else {
                        .col-#{$name}-#{$i} {
                            flex: 0 0 percentage(math.div($i, 12));
                            max-width: percentage(math.div($i, 12));
                        }
                    }
                }

                @media (max-width: #{$size}) {
                    @if $name == "xs" {
                        .col-#{$i} {
                            flex: 0 0 100%;
                            max-width: 100%;
                        }
                    } @else {
                        .col-#{$name}-#{$i} {
                            flex: 0 0 100%;
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }
}

body {
    &.mobile {
        .hide-on-mobile {
            display: none !important;
            pointer-events: none !important;
            touch-action: none !important;
        }
    }
}

.cursor-not-allowed {
    cursor: not-allowed !important;
}

.cursor-default {
    cursor: default !important;
}

.font-weight-600 {
    font-weight: 600 !important;
}

.fieldset {
    @include themes.themify(themes.$themes) {
        border-radius: 0.3rem;
        padding: 0.75rem;
        border: 1px solid themes.themed("background400");

        legend {
            font-size: 1rem;
            line-height: 1rem;
            width: auto;
            padding-left: 5px;
            padding-right: 5px;
            border-radius: 5px;
            font-weight: 500;
            float: unset;
        }
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    @include themes.themify(themes.$themes) {
        $color: mix(
            themes.themed("info200"),
            themes.themed("background100"),
            15%
        );
        -webkit-text-fill-color: themes.textContrast($color);
        -webkit-box-shadow: 0 0 0 100px #{$color} inset;
        transition: background-color 5000s ease-in-out 0s;
    }
}

kbd {
    @include themes.themify(themes.$themes) {
        color: themes.textContrast(themes.themed("background800")) !important;
        background-color: themes.themed("background800") !important;
    }
}

.visibility-hidden {
    visibility: hidden !important;
}

.z-index-1 {
    z-index: 1 !important;
}

.z-index-2 {
    z-index: 2 !important;
}

.z-index-3 {
    z-index: 3 !important;
}

.z-index-999 {
    z-index: 999 !important;
}

.font-size-1 {
    font-size: 1rem !important;
}

.blur-05rem {
    filter: blur(0.5rem);
}

.top-0 {
    top: 0;
}

.right-0 {
    right: 0;
}

.carousel-indicators {
    bottom: -0.5rem;

    button {
        transition:
            opacity 0.6s ease,
            background-color 0.6s ease;

        @include themes.themify(themes.$themes) {
            background-color: themes.themed("secondary500");
        }
    }
}

.capitalize-first {
    &::first-letter {
        text-transform: uppercase;
    }
}

.white-space-normal {
    white-space: normal !important;
}

.scroll-snap {
    scroll-snap-type: x mandatory;
}

.padding-075 {
    padding: 0.75rem !important;
}

.float-right {
    float: right !important;
}

.sticking-def {
    @include themes.themify(themes.$themes) {
        box-shadow: 0 4px 3px -3px themes.themed("background600");
    }
}

.first-letter-lowercase {
    &:first-letter {
        text-transform: lowercase;
    }
}
